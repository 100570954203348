.Card {
    position: relative;
    text-align: center;
    margin: 15px;
    padding: 10px;
    box-shadow: 5px 15px 35px 0px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
}

.Card .icon {
    height: calc(105px + 44px); /* height of icon + margin bottom */
}

.Card .icon img {
    width: 105px;
}

.Card .header {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #222222;
}

.Card .subheader {
    font-size: 14px;
    font-weight: 400;
    color: #222222;
    line-height: 20px;
    padding-bottom: 40px;
}

.Card .button {
    font-size: 16px;
    font-weight: normal;
    color: #0085ff;
    margin-top: 25px;
    cursor: pointer;
    position: absolute;
    
    text-align: center;
    bottom: 15px;
    text-align: center;
    width: 100%;
}