@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
    font-family: 'Public Sans', sans-serif !important;
}

.onlymobile {
    display: none;
}

section {
    width: 100%;
}

.section__content {
    max-width: 1320px;
    margin: 0 auto;
}

.section-header {
    width: fit-content;
    padding: 0px 25px;
    background-color: #E5F3FF;
    border-radius: 50px;

    color: #0085FF;
    font-size: 14px;
    font-weight: bold;
    line-height: 40px;
    letter-spacing: 1px;

    margin: 0 auto;
    margin-bottom: 10px;
    text-transform: uppercase;
}

.cta-button {
    width: fit-content;
    font-size: 16px;
    font-weight: bold;
    color: #FFFFFF;
    background-color: #0085FF;
    border-radius: 10px;
    padding: 24px 60px;
    cursor: pointer;
    transition: background-color .3s;
    box-shadow: 5px 10px 20px 0px rgba(145, 143, 143, 0.3);

    margin: 20px auto;
}

.cta-button:hover {
    background-color: #007CED;
}

/* Header */
header {
    width: 100%;

    background-color: #ffffff;
    position: fixed;
    z-index: 3;
}

header .logo {
    margin-right: 30px;
}

header .section__content {
    display: flex;
    align-items: center;
    height: 82px;
}

header .menu-item {
    cursor: default;
    color: #747D8C;
    font-size: 16px;
    font-weight: normal;

    display: flex;
    flex-direction: column;

    z-index: 4;

    padding: 0 20px;
}

header .menu-item span {
    height: 82px;
    display: flex;

    align-items: center;

    /* -webkit-box-shadow: inset 0px -2px 0px 0px #FFFFFF;
    box-shadow: inset 0px -2px 0px 0px #FFFFFF; */

    transition: .25s;
    position: relative;
}

header .menu-item span:after {
    content: '';
    display: block;
    border-bottom: 3px solid #0085FF;
    width: 0;
    position: absolute;
    -webkit-transition: 1s ease;
    transition: .25s ease;
    bottom: 0;
}

header .menu-item:hover span:after {
    width: 100%;
}

header .menu-item:hover span {
    color: #0085FF;
}

header .menu-item .dropdown {
    display: none;
    position: absolute;
    background-color: #FFFFFF;
    margin-top: 82px;
    z-index: 3;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    overflow: hidden;
}

header .menu-item:hover .dropdown {
    display: unset;
}

header .menu-item .dropdown__item,
header .mobile-menu-item .dropdown__item {
    padding: 12px 16px;

    display: flex;
    align-items: center;
    cursor: pointer;
}

header .mobile-menu-item .dropdown__item {
    padding: 12px 0;
    font-size: 16px;
}

header .menu-item .dropdown__item .icon,
header .mobile-menu-item .dropdown__item .icon {
    margin-right: 8px;
    border-radius: 8px;
    width: 42px;
    height: 42px;

    display: flex;
    justify-content: center;
    align-items: center;
}

header .mobile-menu-item .dropdown__item .icon {
    width: 28px;
    height: 28px;
}

header .menu-item .dropdown__item:hover {
    background-color: #f5f5f5;
}

.full-screen-menu {
    box-sizing: border-box;
    width: 100%;
    height: 100vh;
    position: absolute;
    background-color: #FFFFFF;
    z-index: 5;
    padding: 36px;

    display: flex;
    flex-direction: column;

    transition: .5s;
    transform: translateX(100%);
    overflow: scroll;
}

.full-screen-menu.active {
    transform: translateX(0);
}

.full-screen-menu .mobile-menu-item {
    cursor: pointer;
    color: #000000;
    text-decoration: none;
    margin-bottom: 15px;
    font-size: 24px;
    font-weight: 400;
}

header .mobile-menu-item .dropdown {
    background-color: #FFFFFF;
    z-index: 3;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    overflow: hidden;
}

header .menu-item:hover .dropdown {
    display: unset;
}

.full-screen-menu .fa-xmark {
    position: absolute;
    stroke: #FFFFFF;
    right: 16px;
}

/* First section */

#first-section {
    padding-top: 70px;
    background: url(../assets/images/background.png) 0 50px, linear-gradient(180deg, #E6F3FF 0%, #FFFFFF 100%);
    background-repeat: no-repeat;
    background-size: cover;

    display: flex;
    align-items: center;
    flex-direction: column;
}

#first-section .section__content {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.header {
    text-align: center;
    color: #222222;
    z-index: 2;
}

.header h1 {
    font-size: 23px;
    line-height: 30px;
    font-weight: 700;
    font-style: normal;

    white-space: pre-line;

    margin-bottom: 20px;
}

.header h6 {
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    margin-bottom: 20px;
}

#first-section .input-box-outline {
    width: 595px;
}

#first-section .input-box {
    margin-top: 17px;
    width: 100%;
    box-shadow: 0px 10px 30px -12px rgb(77 77 77 / 20%);
    border-radius: 50px;
    overflow: hidden;

    display: flex;
    flex-direction: row;
    align-items: center;

    background-color: #FFFFFF;
}

#first-section .input-box input {
    padding: 18px 16px;
    width: 100%;
    height: 61px;
    box-sizing: border-box;
}

#first-section .input-box .button {
    background-color: #0085FF;
    color: #FFFFFF;
    white-space: nowrap;

    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    border-radius: 50px;
    padding: 12px 18px 14px 18px;
    box-sizing: border-box;

    margin-right: 8px;
    cursor: pointer;
}

#first-section img.image {
    margin-top: 50px;
    width: 75%;
    height: 400px;

    object-fit: cover;
}

#first-section .people {
    pointer-events: none;
    position: absolute;
    width: 100%;

    display: flex;
    justify-content: space-between;

    z-index: 1;
    margin-top: 100px;
    padding-left: 50px;
    padding-right: 50px;
    box-sizing: border-box;
}

#first-section .people img {
    max-width: 200px;
    object-fit: contain;
}

#first-section .subheader {
    font-size: 16px;
    color: #747D8C;

    padding: 30px 0;
    max-width: 75%;
    text-align: center;
    line-height: 21px;
}

/* Second section */

#second-section .section__content {
    padding: 50px 0 70px 0;
    flex: 1;
}

#second-section .cards {
    display: flex;
    flex-wrap: wrap;
}

#second-section .cards .Card {
    flex: 1 1 20%;
}

/* Third section */
#third-section {
    background-color: #f5f7fb;
}

#third-section .section__content {
    padding: 70px 0;
}

#third-section img.quote {
    position: absolute;
    width: 105px;
    transform: translate(0, -50%);
    margin-left: 25%;
}

#third-section .header h6 {
    color: #747D8C;
}

.row {
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

#third-section .row:not(:last-child) {
    margin-bottom: 150px;
}

.row .row__side-content {
    width: 50%;
}

.row img {
    width: 100%;
}

.row .row__header {
    color: #222222;
    font-size: 23px;
    font-weight: bold;
    margin-bottom: 20px;
}

.row__subheader {
    color: #222222;
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 20px;
}

.row__subheader2 {
    color: #747D8C;
    opacity: .75;
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 20px;
}

.row .row__button {
    display: block;
    width: fit-content;
    font-size: 14px;
    font-weight: bold;
    color: #0085FF;
    -webkit-box-shadow: 0px 0px 0px 1px #0085FF;
    box-shadow: 0px 0px 0px 1px #0085FF;
    border-radius: 50px;
    padding: 18px 35px;
    cursor: pointer;
    transition: box-shadow .3s, background-color .3s;
    text-decoration: none;
}

.row .row__button:hover {
    -webkit-box-shadow: 0px 0px 0px 2px #0085FF;
    box-shadow: 0px 0px 0px 2px #0085FF;
    background-color: #E2F0FE;
    font-weight: 800;
}

#third-section .list .row:nth-child(even) {
    flex-direction: row-reverse;
}

#third-section .list .row:nth-child(even) .row__header,
#third-section .list .row:nth-child(even) .row__subheader,
#third-section .list .row:nth-child(even) .row__button {
    text-align: right;
    float: right;
}

/* Fourth section */
#fourth-section .section__content {
    padding: 70px 0;
}

#fourth-section .section__content .list {
    display: flex;
    flex-wrap: wrap;
}

#fourth-section .section__content .list .Testimonial {
    flex: 1 1 40%;
}

/* Fifth section */
/* #fifth-section {
    background-color: #f5f7fb;
}

#fifth-section .section__content {
    padding: 70px 0;
} */

#fifth-section .section__content .divider-text {
    display: flex;
    align-items: center;

    margin-bottom: 20px;
}

#fifth-section .section__content .divider-text span {
    text-transform: uppercase;
    text-align: center;
    margin: 0 16px;

    color: #A4B0BE;
    font-weight: 400;
    font-size: 15px;
}

#fifth-section .section__content .divider-text::before,
#fifth-section .section__content .divider-text::after {
    display: block;
    content: "";
    border-bottom: 0;
    flex-grow: 1;
    border-top: 2px solid #E1E1E1;
}

#fifth-section .header .body,
#sixth-section .header .body {
    margin: 0 auto;
    font-size: 16px;
    color: #747D8C;

    max-width: 50%;
    text-align: center;
    line-height: 21px;
}

#fifth-section .companies-list::-webkit-scrollbar {
    display: none;
}

#fifth-section .companies-overflow {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
}

/* #fifth-section .companies-overflow:hover {
    overflow: auto;
}

#fifth-section .companies-overflow:hover .companies-list {
    animation-play-state:paused;
} */

#fifth-section .companies-list {
    -ms-overflow-style: none;
    scrollbar-width: none;

    display: flex;
    align-items: center;
    /* overflow: hidden; */
    white-space: nowrap;

    margin: 50px auto;
    transform-style: preserve-3d;
}

#fifth-section .companies-list:not(.withoutscroll) {
    transition: none;
    animation: move 35s infinite linear;
    margin: 50px 0;
}

@keyframes move {
    100% {
        transform: translate(-100%, 0);
    }
}

#fifth-section .companies-list .company {
    margin: 0 25px;
    filter: grayscale(1);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
}

/* Sixth content */
#sixth-section .section__content {
    padding: 70px 0;
}

#sixth-section .header h6 {
    color: #0085FF;
}

#sixth-section img {
    padding-bottom: 70px;
}

/* Seventh content */
#seventh-section {
    background-color: #0085FF;
    background-image: url(../assets/images/BG2.png);
}

#seventh-section .section__content {
    padding: 70px 0;
}

#seventh-section .header .body {
    margin: 0 auto;
    font-size: 15px;
    opacity: 0.5;
    margin-bottom: 20px;
    line-height: 21px;
}

#seventh-section .section__content {
    display: flex;
}

#seventh-section .section__content .side-content {
    width: 50%;
    box-sizing: border-box;
}

#seventh-section .section__content .side-content:nth-child(2n) {
    padding-left: 50px;
}

#seventh-section .section__content .side-content .section-header {
    margin: unset;
    margin-bottom: 20px;
    background-color: #1991FF;
    color: #FFFFFF;
}

#seventh-section .section__content .side-content .header {
    text-align: left;
    color: #FFFFFF;
}

#seventh-section .section__content .side-content .header .button {
    background-color: #fff;
    color: #222222;
    border: 1px solid #222222;
    border-radius: 50px;
    font-size: 16px;

    width: fit-content;
    padding: 15px 30px;
    cursor: pointer;
    transition: .3s;
}

#seventh-section .section__content .side-content .header .button:hover {
    background-color: #d8d8d8;
}

/* Eighth section */
#eighth-section .section__content {
    padding: 70px 0;
}

/* Ninth section */
#contact-us {
    padding-bottom: 35px;
}

#contact-us .section__content .form {
    margin: 0 auto;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    width: 280px;
}

#contact-us .section__content input {
    height: 45px;
}

#contact-us .section__content textarea {
    height: 90px;
}

#contact-us .section__content input,
#contact-us .section__content textarea {
    box-sizing: border-box;
    border-radius: 12px;
    margin: 6px 0;
    background-color: #f5f5f5;
    padding: 8px 12px;
}

#contact-us .section__content .form .button {
    margin-top: 6px;
    height: 45px;
    background-color: #0085FF;
    color: #FFFFFF;
    border-radius: 12px;

    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 1400px) {
    .section__content {
        max-width: 1140px;
    }

    #second-section .cards .Card {
        flex: 1 1 40%;
    }
}

@media screen and (max-width: 1200px) {
    .section__content {
        max-width: 960px;
    }

    #first-section .people {
        display: none;
    }
}

@media screen and (max-width: 992px) {
    .section__content {
        max-width: 720px;
    }

    .header h1 {
        font-size: 20px;
    }

    .header h6 {
        font-size: 14px;
    }
}

@media screen and (max-width: 768px) {
    .section__content {
        max-width: 100%;
        padding-left: 16px !important;
        padding-right: 16px !important;
    }

    header .section__content {
        justify-content: space-between;
    }

    #first-section {
        background: linear-gradient(180deg, #E6F3FF 0%, #FFFFFF 100%);
    }

    #first-section img.image {
        width: 100%;
        height: 200px;
    }

    #first-section .header {
        padding: 0 20px;
    }

    #first-section .input-box-outline {
        width: 100%;
        box-sizing: border-box;
        padding-left: 20px;
        padding-right: 20px;
    }

    #first-section .subheader {
        max-width: unset;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
    }

    #second-section .cards {
        margin: 0 10px;
    }

    #second-section .cards .Card {
        flex: 1 1 100%;
    }

    #fourth-section .section__content .list .Testimonial {
        flex: 1 1 100%;
    }

    #fourth-section .section__content .list {
        margin: 0 10px;
    }

    #fifth-section .header .body,
    #sixth-section .header .body {
        max-width: unset;
    }

    #seventh-section .section__content {
        flex-direction: column;
    }

    #seventh-section .section__content .side-content {
        width: 100%;
    }

    #seventh-section .section__content .side-content:nth-child(2n) {
        margin-top: 20px;
        padding-left: unset;
    }

    .onlyweb {
        display: none !important;
    }

    .onlymobile {
        display: unset;
    }

    .row .row__header {
        font-size: 24px;
    }
}

@media screen and (max-width: 576px) {
    .section__content {
        max-width: 100%;
    }

    .row {
        flex-direction: column !important;
    }

    #third-section .row {
        margin-bottom: 40px!important;
    }

    .row .row__side-content {
        width: 100% !important;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .row .row__side-content * {
        text-align: center !important;
        float: unset !important;
        margin-left: auto;
        margin-right: auto;
    }
}