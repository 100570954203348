.CancellationPolicy {
    padding-top: 30px;
    /* background: url(../assets/images/background.png) 0 50px, linear-gradient(180deg, #E6F3FF 0%, #FFFFFF 100%);
    background-repeat: no-repeat;
    background-size: cover; */
    padding-bottom: 100px;
}

.CancellationPolicy span {
    color: #0085FF;
}

.CancellationPolicy .header {
    color: #222222;
    z-index: 2;
}

.CancellationPolicy .header h1 {
    font-size: 40px;
    font-weight: 700;
    font-style: normal;

    white-space: pre-line;

    margin-bottom: 20px;
}

.CancellationPolicy .header h6 {
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    margin-bottom: 20px;
}

.CancellationPolicy .header .body {
    text-align: left;
    margin: 0 auto;
    font-size: 16px;
    color: #747D8C;

    max-width: 55%;
    line-height: 21px;
}

.CancellationPolicy .header h5 {
    font-weight: 700;
    font-size: 18px;
    margin-top: 20px;
}

@media screen and (max-width: 768px) {
    .CancellationPolicy .header .body {
        max-width: unset;
    }
}