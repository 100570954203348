.About {
    padding-top: 30px;
    /* background: url(../assets/images/background.png) 0 50px, linear-gradient(180deg, #E6F3FF 0%, #FFFFFF 100%);
    background-repeat: no-repeat;
    background-size: cover; */
    padding-bottom: 100px;
}

.About span {
    color: #0085FF;
}

.About .header {
    text-align: center;
    color: #222222;
    z-index: 2;
}

.About .header h1 {
    font-size: 40px;
    font-weight: 700;
    font-style: normal;

    white-space: pre-line;

    margin-bottom: 20px;
}

.About .header h6 {
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    margin-bottom: 20px;
}

.About .header .body {
    margin: 0 auto;
    font-size: 16px;
    color: #747D8C;

    max-width: 55%;
    text-align: center;
    line-height: 21px;
}

.About .header p {
    margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
    .About .header .body {
        max-width: unset;
    }
}