.Testimonial {
    position: relative;
    margin: 15px;
    padding: 10px;
    box-shadow: 5px 15px 35px 0px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
}

.Testimonial .fa-quote-right {
    padding: 15px 0px 0px 20px;
}

.Testimonial .content {
    margin: 20px 35px 25px 45px;
}

.Testimonial .content .text {
    color: #222222;
    font-family: "Public Sans", Sans-serif;
    font-size: 24px;
    font-weight: normal;
    line-height: 36px;
}

.Testimonial .content .company-content {
    margin-top: 20px;
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.Testimonial .content .company-content .logo {
    padding-right: 15px;
}

.Testimonial .content .company-content .logo img {
    width: 120px;
}

@media screen and (max-width: 576px) {
    .Testimonial .content {
        margin: 20px;
    }

    .Testimonial .content .text {
        font-size: 14px;
        font-weight: normal;
        line-height: 25px;
    }
}