footer .section__content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 50px;
}

footer .section__content .column {
    width: 25%;
}

footer .column .footer__header {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 12px;
}

footer .column .footer__item {
    font-weight: 400;
    font-size: 14px;
    color: #747D8C;
    padding: 7px 0;
    cursor: pointer;
}

footer .column.social .footer__item {
    padding: 0 8px;
}

footer .copyright {
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: #747D8C;
    margin-bottom: 20px;
}

footer a {
    text-decoration: none;
}

footer  .contact-section {
    background-color: #f2f2f2; /* Clear grey background for contact section */
 
    width: 100vw; /* Full viewport width */
    border-radius: 5px;
    padding: 20px;
  
    
}

footer .contact_us .column {
    width: 50%;
}
/* Media queries */

@media screen and (max-width: 768px) {
    footer .section__content .column {
        width: 50%;
    }

    footer .section__content .column.onlymobile {
        display: flex;
        align-items: center;
    }
}